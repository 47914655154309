import React, { useState, useEffect } from "react";
import "./styles.scss";
import UnsubscribeSuccess from "./success";
import { EMAIL_REGEX } from "../../../../config/config.js";
import { CONVEX_API_URL } from "../../../../config/config.js";
import Design from "./Design.jsx";

function UnsubscribeForm(props) {
  const [btnClicked, setBtnClicked] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [showSuccessPage, setShowSuccessPage] = useState(false);
  const [selectedReason, setSelectedReason] = useState("");
  const [email, setEmail] = useState("");
  const [emailExists, setEmailExists] = useState(true);
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [subscribed, setSubscribed] = useState(false);

  const [errorOccured, setErrorOccured] = useState(false);
  let params = new URLSearchParams(props.search);
  let campaignId = params.get("campaign_id");

  const reason_options = [
    "Your emails are not relevant to me",
    "Your emails are too frequent",
    "I am not liking the content that’s coming ",
    "I don’t remember signing up for these emails",
    "I am no more interested in a marketing career",
    "I am signed up for there from my other email id",
  ];

  useEffect(() => {
    console.log(params);

    let email = params.get("email");
    
    setEmail(email);

    if (email && EMAIL_REGEX.test(email)) {
      checkSubscription(email);
      setIsValidEmail(true);
    } else {
      setIsValidEmail(false);
    }
  }, []);

  const checkSubscription = async (email) => {
    try {
      let data = { email };
      // let response = await fetch(
      //   `${CONVEX_API_URL}/lead/email-subscription/status`,
      //   {
      //     method: "post",
      //     headers: { "Content-Type": "application/json" },
      //     body: JSON.stringify(data),
      //   }
      // );
      let response = await fetch(`${CONVEX_API_URL}/promotional-campaign-lead/check-status?campaign_id=${campaignId}&email=${email}`, {
        headers: { "Content-Type": "application/json" },
      });
      let resData = await response.json();

      // if (resData.status === 400) {
      //   setEmailExists(false);
      // }

      console.log("resData======", resData);

      if (resData.status == "YES" || resData.status === "NO") {
        setEmailExists(true);
        
        setSubscribed(resData.status == "YES");
      } else {
        setEmailExists(false);
      }


    } catch (err) {
      setErrorOccured(true);
      console.log("err", err);
    }
  };

  const getValuesFn = (data) => {
    setSelectedReason(data.value);
  };

  const submitFn = async (e) => {
    e.preventDefault();
    setBtnClicked(true);
    setErrorOccured(false);

    setBtnLoading(true);
    const data = {
      email: email,
      reason: selectedReason,
      unsubscribe: "yes",
    };

    try {
      //OLD CLASSAPI PLATFORM API
      // let response = await fetch(
      //   `${API_URL}/unsubscribe/leads-email?email=${email}&reason=${selectedReason}`
      // );
      //CONVEX PLATFORM API
      let response = await fetch(`${CONVEX_API_URL}/promotional-campaign-lead/unsubscribe?campaign_id=${campaignId}&email=${email}`, {
        headers: { "Content-Type": "application/json" },
      });
      let resData = await response.json();

      if (resData.message == "SUCCESS") {
        setShowSuccessPage(true);
      } else {
        setErrorOccured(true);
      }

      setBtnLoading(false);
    } catch (err) {
      setBtnLoading(false);
      setErrorOccured(true);
      console.log("err", err);
    }
  };


  console.log("!isValidEmail", !isValidEmail);

  return (
    <div className="unsubscribe-formWrap">
      {
        (!isValidEmail || !emailExists) && <Design>
          <div className="unsubscribe-desc">Email does not exists!</div>
        </Design>
      }

      {
        isValidEmail && emailExists && !subscribed && <Design>
          <div className="unsubscribe-desc">Email already unsubscribed!</div>
        </Design>
      }

      {
        isValidEmail && emailExists && subscribed && <div className="unsubscribe-contact-form">
          {showSuccessPage ? <UnsubscribeSuccess /> : (
            <Design>
              <div className="unsubscribe-title">Unsubscribe</div>
              <div className="unsubscribe-hr"></div>
              <div className="unsubscribe-desc">
                Are you sure you want to unsubscribe?
              </div>
              <button className="unsubscribe-btn" onClick={submitFn}>
                Confirm
              </button>
              {errorOccured && <div className="error-unsubscribe">Something went wrong. Please try again later</div>}
            </Design>
          )}
        </div>
      }

    </div>
  );
}

export default UnsubscribeForm;
