import React from "react";


const Design = ({ children }) => {
  return (
    <div className="unsubscribe-success-group">
      <img src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m237naz8.png" className="rightDots" loading="lazy" />
      <img src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86yzpcm28ljfbf.png" className="leftDots" loading="lazy" />
      <img src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86yzpcm28m1ko8.png" className="bgShape" loading="lazy" />
      <div className="unsubscribe-card">{children}</div>
    </div>
  );
};

export default Design;
